/**
 * @typedef MatchCodeConfidence
 */
export const enum MatchCodeConfidence {
  /**
   * An exact match.
   */
  exact = 'exact',
  /**
   * High confidence of a match.
   */
  high = 'high',
  /**
   * Medium confidence of a match.
   */
  medium = 'medium',
  /**
   * Low confidence of a match.
   */
  low = 'low'
}

/**
 * An object describing the level of confidence that the given response feature matches the address intended by the request query.
 * Includes boolean flags denoting matches for each address sub-component.
 *
 * @typedef MatchCode
 */
export interface MatchCode {
  /**
   * A measure of confidence that the returned feature suggestion matches the intended address, based on the search text provided.
   */
  confidence: MatchCodeConfidence;
  /**
   * True if the confidence value is "exact".
   */
  exact_match: boolean;
  /**
   * True if the house number component was matched.
   */
  house_number: boolean;
  /**
   * True if the street component was matched.
   */
  street: boolean;
  /**
   * True if the postcode was matched.
   */
  postcode: boolean;
  /**
   * True if the place component was matched.
   */
  place: boolean;
  /**
   * True if the region component was matched.
   */
  region?: boolean;
  /**
   * True if the locality component was matched.
   */
  locality?: boolean;
}
